.Accordion{
    background: #f4f4f4;
    margin-bottom: 30px;
    border-radius: 10px;
    border-bottom:1px solid #CB0F0F;
}

.Accordion__tab{
    width: 100%;
    background: #CB0F0F;
    font-family: 'Mountains of Christmas', cursive;
    padding: 1rem 53px;
    font-size:25px;
    color:white;
    border-radius: 10px;
    cursor: pointer;
    transition: all 300ms ease;
}

.Accordion__content{
    max-height: 0;
    width:100%;
    overflow: hidden;
    transition: all 400ms ease;
}

.open .Accordion__tab{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.open .Accordion__content{
    max-height: 10000px;
}