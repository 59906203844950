#about {
    background-color: white;
    padding: 75px 0;
}

#about > div{
    max-width: 576px;
    margin: 0 auto;
}

#about h1 {
    font-family: 'Mountains of Christmas', cursive;
    color: red;
    text-align: center;
}

#about h2 {
    text-transform: uppercase;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 25px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.5em;
}

#about p {
    margin-top:25px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    padding: 0em 2em;
}