@import url(https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background-color: black;
}

section {
    padding: 50px 0px;
}

/* snow scene*/
.svg-snowscene {
    width: 100%;
}

.snow {
    fill: #fff;
    -webkit-animation-name: snowing;
            animation-name: snowing;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
}

.snow:nth-child(2n) {
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s;
}

.snow:nth-child(3n) {
    -webkit-animation-delay: 2.3s;
            animation-delay: 2.3s;
    -webkit-animation-duration: 3.3s;
            animation-duration: 3.3s;
}

.snow:nth-child(4n) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
    -webkit-animation-duration: 3.2s;
            animation-duration: 3.2s;
}

.snow:nth-child(5n) {
    -webkit-animation-delay: 2.8s;
            animation-delay: 2.8s;
}

@-webkit-keyframes snowing {
    0% {
        fill-opacity: 1;
    }
    100% {
        fill-opacity: 0;
        transform: translateY(200px);
    }
}

@keyframes snowing {
    0% {
        fill-opacity: 1;
    }
    100% {
        fill-opacity: 0;
        transform: translateY(200px);
    }
}
/* BOOK TICKETS */
.book-now-btn {
    padding: 25px 32px;
    background: #CB0F0F;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 0.5em;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
}

.book-now-btn.outline{
    background: transparent;
    padding:10px 0;
}

@media only screen and (max-width: 375px) {
    .book-now-btn {
        padding: 5px;
    }
}
.TopBanner {
    background-color: black;
    background: url(/static/media/woodland-background.e0d8d4ac.jpg);
    background-size: cover;
}

.nav-logo {
    width: 185px;
}

@media only screen and (max-width: 375px) {
    .nav-logo {
        width: 95px;
    }
}
#about {
    background-color: white;
    padding: 75px 0;
}

#about > div{
    max-width: 576px;
    margin: 0 auto;
}

#about h1 {
    font-family: 'Mountains of Christmas', cursive;
    color: red;
    text-align: center;
}

#about h2 {
    text-transform: uppercase;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 25px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.5em;
}

#about p {
    margin-top:25px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    padding: 0em 2em;
}
#availabledates {
    padding: 120px 0;
    background-color: #2A0000;
    color: white;
}

#availabledates h1 {
    font-family: 'Mountains of Christmas', cursive;
    color: red;
    text-align: center;
}

#availabledates h2 {
    text-transform: uppercase;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 25px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.5em;
}

#availabledates .option {
    background: linear-gradient(180deg, #280101 21.12%, #5D0505 100%);
    border: 1px solid #CB0F0F;
    box-sizing: border-box;
    border-radius: 10px;
    padding:40px 30px 0 30px;
}

#availabledates .option h1 {
    color: white;
    text-align: left;
}

#availabledates .calendar{
    margin-top: 82px;
}

#availabledates .option h2 {
    color: red;
    text-align: left;
    letter-spacing: 0em;
}

#availabledates .book-now-btn {
    padding-top:0;
}

@media only screen and (max-width: 375px) {
    #availabledates .option h1 {
        font-size: 26px;
    }
}
.WoodlandCTA{
    border-radius: 10px;
    max-width: 1132px;
    background-size: cover;
    min-height: 410px;
    display: flex;
    align-items: center;
    margin:0 auto;
    width:100%;
}

.WoodlandCTA > img{
    max-width:75%;
}

@media (max-width: 698px){
    .WoodlandCTA{
        max-width:90%;
        flex-direction: column;
        justify-content: space-evenly;
    }
}

.WoodlandCTA > *{
    margin:0 auto;
}
.footer{
    background:white;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding-top:100px;
    /* or 150% */
    letter-spacing: -0.02em;

    color: #02323D;
}
.footer a{
    color: #02323D;
    font-weight: bold;
}
.footer a:hover{
    background:none;
    text-decoration: underline;
    font-weight: bold;
}
.footer__top{
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.footer hr{
    margin: 40px 0;
}
.footer > div{
    width:80%;
    margin: 0 auto;
}
.footer__flex{
    display:flex;
    justify-content: space-evenly;
}
@media (max-width:798px){
    .footer__flex{
        flex-direction: column;
    }
    .footer__flex * {
        text-align: center;
    }
}
.footer__flex > * {
    width:100%;
}
.Footer__socials{
    display:flex;
}
.Footer__socials a{
    background:#00141A;
    border-radius: 50%;
    width:42px;
    display:block;
    text-align: center;
    margin:0 10px;
    transition: all 300ms ease;
}
.Footer__socials a:hover{
    background:#02323D;
}
.Footer__socials a img{
    padding:14px 0;
}
.Accordion{
    background: #f4f4f4;
    margin-bottom: 30px;
    border-radius: 10px;
    border-bottom:1px solid #CB0F0F;
}

.Accordion__tab{
    width: 100%;
    background: #CB0F0F;
    font-family: 'Mountains of Christmas', cursive;
    padding: 1rem 53px;
    font-size:25px;
    color:white;
    border-radius: 10px;
    cursor: pointer;
    transition: all 300ms ease;
}

.Accordion__content{
    max-height: 0;
    width:100%;
    overflow: hidden;
    transition: all 400ms ease;
}

.open .Accordion__tab{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.open .Accordion__content{
    max-height: 10000px;
}
.FAQs{
    background:white;
}

.FAQs__meta{
    max-width: 576px;
    margin: 120px auto;
}

.FAQs h2{
    font-family: 'Mountains of Christmas', cursive;
    color: red;
    text-align: center;
    font-size: 50px;
    line-height: 1;
}
    
.FAQs h3{
    text-transform: uppercase;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 25px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.5em;
}

.FAQs .FAQ__title{
    font-weight: 800;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.02em;
    color: #CB0F0F;
}

.FAQs .FAQ{
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    padding:50px;
    position: relative;
}
.FAQs .FAQ:after{
    content:"";
    position: absolute;
    bottom:0;
    left:50px;
    width:calc(100% - 100px);
    border-bottom:  1px dashed #BBBBBB;
}
.FAQs .FAQ:last-child:after{
    content:none;
    position: absolute;
    bottom:0;
    left:50px;
    width:calc(100% - 100px);
    border-bottom:  1px dashed #BBBBBB;
}
