.WoodlandCTA{
    border-radius: 10px;
    max-width: 1132px;
    background-size: cover;
    min-height: 410px;
    display: flex;
    align-items: center;
    margin:0 auto;
    width:100%;
}

.WoodlandCTA > img{
    max-width:75%;
}

@media (max-width: 698px){
    .WoodlandCTA{
        max-width:90%;
        flex-direction: column;
        justify-content: space-evenly;
    }
}

.WoodlandCTA > *{
    margin:0 auto;
}