.footer{
    background:white;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    padding-top:100px;
    /* or 150% */
    letter-spacing: -0.02em;

    color: #02323D;
}
.footer a{
    color: #02323D;
    font-weight: bold;
}
.footer a:hover{
    background:none;
    text-decoration: underline;
    font-weight: bold;
}
.footer__top{
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.footer hr{
    margin: 40px 0;
}
.footer > div{
    width:80%;
    margin: 0 auto;
}
.footer__flex{
    display:flex;
    justify-content: space-evenly;
}
@media (max-width:798px){
    .footer__flex{
        flex-direction: column;
    }
    .footer__flex * {
        text-align: center;
    }
}
.footer__flex > * {
    width:100%;
}
.Footer__socials{
    display:flex;
}
.Footer__socials a{
    background:#00141A;
    border-radius: 50%;
    width:42px;
    display:block;
    text-align: center;
    margin:0 10px;
    transition: all 300ms ease;
}
.Footer__socials a:hover{
    background:#02323D;
}
.Footer__socials a img{
    padding:14px 0;
}