@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&display=swap');
body {
    background-color: black;
}

section {
    padding: 50px 0px;
}

/* snow scene*/
.svg-snowscene {
    width: 100%;
}

.snow {
    fill: #fff;
    animation-name: snowing;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

.snow:nth-child(2n) {
    animation-delay: 1.5s;
}

.snow:nth-child(3n) {
    animation-delay: 2.3s;
    animation-duration: 3.3s;
}

.snow:nth-child(4n) {
    animation-delay: 0.8s;
    animation-duration: 3.2s;
}

.snow:nth-child(5n) {
    animation-delay: 2.8s;
}

@keyframes snowing {
    0% {
        fill-opacity: 1;
    }
    100% {
        fill-opacity: 0;
        transform: translateY(200px);
    }
}