#availabledates {
    padding: 120px 0;
    background-color: #2A0000;
    color: white;
}

#availabledates h1 {
    font-family: 'Mountains of Christmas', cursive;
    color: red;
    text-align: center;
}

#availabledates h2 {
    text-transform: uppercase;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 25px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.5em;
}

#availabledates .option {
    background: linear-gradient(180deg, #280101 21.12%, #5D0505 100%);
    border: 1px solid #CB0F0F;
    box-sizing: border-box;
    border-radius: 10px;
    padding:40px 30px 0 30px;
}

#availabledates .option h1 {
    color: white;
    text-align: left;
}

#availabledates .calendar{
    margin-top: 82px;
}

#availabledates .option h2 {
    color: red;
    text-align: left;
    letter-spacing: 0em;
}

#availabledates .book-now-btn {
    padding-top:0;
}

@media only screen and (max-width: 375px) {
    #availabledates .option h1 {
        font-size: 26px;
    }
}