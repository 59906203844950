.FAQs{
    background:white;
}

.FAQs__meta{
    max-width: 576px;
    margin: 120px auto;
}

.FAQs h2{
    font-family: 'Mountains of Christmas', cursive;
    color: red;
    text-align: center;
    font-size: 50px;
    line-height: 1;
}
    
.FAQs h3{
    text-transform: uppercase;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 25px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.5em;
}

.FAQs .FAQ__title{
    font-weight: 800;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.02em;
    color: #CB0F0F;
}

.FAQs .FAQ{
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    padding:50px;
    position: relative;
}
.FAQs .FAQ:after{
    content:"";
    position: absolute;
    bottom:0;
    left:50px;
    width:calc(100% - 100px);
    border-bottom:  1px dashed #BBBBBB;
}
.FAQs .FAQ:last-child:after{
    content:none;
    position: absolute;
    bottom:0;
    left:50px;
    width:calc(100% - 100px);
    border-bottom:  1px dashed #BBBBBB;
}