.TopBanner {
    background-color: black;
    background: url('../woodland-background.jpg');
    background-size: cover;
}

.nav-logo {
    width: 185px;
}

@media only screen and (max-width: 375px) {
    .nav-logo {
        width: 95px;
    }
}