/* BOOK TICKETS */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
.book-now-btn {
    padding: 25px 32px;
    background: #CB0F0F;
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 0.5em;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
}

.book-now-btn.outline{
    background: transparent;
    padding:10px 0;
}

@media only screen and (max-width: 375px) {
    .book-now-btn {
        padding: 5px;
    }
}